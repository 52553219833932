import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Playground } from "docz";
import { Table, Caption, Tr, Th, Td } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "table"
    }}>{`Table`}</h1>
    <h2 {...{
      "id": "default-table"
    }}>{`Default table`}</h2>
    <Playground __position={0} __code={'<Table>\n  <Caption>Caption</Caption>\n  <thead>\n    <Tr>\n      <Th>Head 1</Th>\n      <Th>Head 2</Th>\n      <Th textAlign=\"right\">Number</Th>\n    </Tr>\n  </thead>\n  <tbody>\n    <Tr>\n      <Td>Value 1</Td>\n      <Td>Important content here</Td>\n      <Td textAlign=\"right\">1</Td>\n    </Tr>\n    <Tr>\n      <Td>Value 2</Td>\n      <Td>Even more important content down here</Td>\n      <Td textAlign=\"right\">2</Td>\n    </Tr>\n  </tbody>\n  <tfoot>\n    <Tr>\n      <Td colSpan={2}>VAT 25%</Td>\n      <Td textAlign=\"right\">9900</Td>\n    </Tr>\n  </tfoot>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Table,
      Caption,
      Tr,
      Th,
      Td,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Table mdxType="Table">
    <Caption mdxType="Caption">Caption</Caption>
    <thead>
      <Tr mdxType="Tr">
        <Th mdxType="Th">Head 1</Th>
        <Th mdxType="Th">Head 2</Th>
        <Th textAlign="right" mdxType="Th">Number</Th>
      </Tr>
    </thead>
    <tbody>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 1</Td>
        <Td mdxType="Td">Important content here</Td>
        <Td textAlign="right" mdxType="Td">1</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 2</Td>
        <Td mdxType="Td">Even more important content down here</Td>
        <Td textAlign="right" mdxType="Td">2</Td>
      </Tr>
    </tbody>
    <tfoot>
      <Tr mdxType="Tr">
        <Td colSpan={2} mdxType="Td">VAT 25%</Td>
        <Td textAlign="right" mdxType="Td">9900</Td>
      </Tr>
    </tfoot>
  </Table>
    </Playground>
    <h2 {...{
      "id": "simple"
    }}>{`Simple`}</h2>
    <p>{`Simplified table for use in toggled sections and such.`}</p>
    <Playground __position={1} __code={'<Table variant=\"simple\">\n  <Caption>Caption</Caption>\n  <thead>\n    <Tr>\n      <Th>Head 1</Th>\n      <Th>Head 2</Th>\n      <Th textAlign=\"right\">Number</Th>\n    </Tr>\n  </thead>\n  <tbody>\n    <Tr>\n      <Td>Value 1</Td>\n      <Td>Important content here</Td>\n      <Td textAlign=\"right\">1</Td>\n    </Tr>\n    <Tr>\n      <Td>Value 2</Td>\n      <Td>Even more important content down here</Td>\n      <Td textAlign=\"right\">2</Td>\n    </Tr>\n  </tbody>\n  <tfoot>\n    <Tr>\n      <Td colSpan={2}>VAT 25%</Td>\n      <Td textAlign=\"right\">9900</Td>\n    </Tr>\n  </tfoot>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Table,
      Caption,
      Tr,
      Th,
      Td,
      DefaultLayout,
      _frontmatter
    }} style={{
      backgroundColor: "#F5F8F9"
    }} mdxType="Playground">
  <Table variant="simple" mdxType="Table">
    <Caption mdxType="Caption">Caption</Caption>
    <thead>
      <Tr mdxType="Tr">
        <Th mdxType="Th">Head 1</Th>
        <Th mdxType="Th">Head 2</Th>
        <Th textAlign="right" mdxType="Th">Number</Th>
      </Tr>
    </thead>
    <tbody>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 1</Td>
        <Td mdxType="Td">Important content here</Td>
        <Td textAlign="right" mdxType="Td">1</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 2</Td>
        <Td mdxType="Td">Even more important content down here</Td>
        <Td textAlign="right" mdxType="Td">2</Td>
      </Tr>
    </tbody>
    <tfoot>
      <Tr mdxType="Tr">
        <Td colSpan={2} mdxType="Td">VAT 25%</Td>
        <Td textAlign="right" mdxType="Td">9900</Td>
      </Tr>
    </tfoot>
  </Table>
    </Playground>
    <h2 {...{
      "id": "small"
    }}>{`Small`}</h2>
    <Playground __position={2} __code={'<Table small>\n  <Caption>Caption</Caption>\n  <thead>\n    <Tr>\n      <Th>Head 1</Th>\n      <Th>Head 2</Th>\n      <Th textAlign=\"right\">Number</Th>\n    </Tr>\n  </thead>\n  <tbody>\n    <Tr>\n      <Td>Value 1</Td>\n      <Td>Important content here</Td>\n      <Td textAlign=\"right\">1</Td>\n    </Tr>\n    <Tr>\n      <Td>Value 2</Td>\n      <Td>Even more important content down here</Td>\n      <Td textAlign=\"right\">2</Td>\n    </Tr>\n  </tbody>\n  <tfoot>\n    <Tr>\n      <Td colSpan={2}>VAT 25%</Td>\n      <Td textAlign=\"right\">9900</Td>\n    </Tr>\n  </tfoot>\n</Table>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Playground,
      Table,
      Caption,
      Tr,
      Th,
      Td,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Table small mdxType="Table">
    <Caption mdxType="Caption">Caption</Caption>
    <thead>
      <Tr mdxType="Tr">
        <Th mdxType="Th">Head 1</Th>
        <Th mdxType="Th">Head 2</Th>
        <Th textAlign="right" mdxType="Th">Number</Th>
      </Tr>
    </thead>
    <tbody>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 1</Td>
        <Td mdxType="Td">Important content here</Td>
        <Td textAlign="right" mdxType="Td">1</Td>
      </Tr>
      <Tr mdxType="Tr">
        <Td mdxType="Td">Value 2</Td>
        <Td mdxType="Td">Even more important content down here</Td>
        <Td textAlign="right" mdxType="Td">2</Td>
      </Tr>
    </tbody>
    <tfoot>
      <Tr mdxType="Tr">
        <Td colSpan={2} mdxType="Td">VAT 25%</Td>
        <Td textAlign="right" mdxType="Td">9900</Td>
      </Tr>
    </tfoot>
  </Table>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      